import { SelectionChangedEvent } from "ag-grid-community";
import { CreditNoteHeaderEntity } from "domain/entity/CreditNote/CreditNoteHeaderEntity";
import { INITIAL_CREDIT_NOTE_HEADER_COL_DEF, transferRowData } from "presentation/constant/CreditNote/CreditNoteHeaderColumnDefinition";
import { useCreditNoteHeaderVM } from "presentation/hook/CreditNote/useCreditNoteHeaderVM";
import { useCreditNoteHeaderMaintenanceTracked } from "presentation/store/CreditNote/CreditNoteHeaderMaintenanceProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";
const CreditNoteHeaderTablePanel:React.FC = () => {
    const [ creditNoteHeaderState ] = useCreditNoteHeaderMaintenanceTracked();
    const creditNoteHeaderVM = useCreditNoteHeaderVM();
    let gridRef: any = useRef();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_CREDIT_NOTE_HEADER_COL_DEF.slice());
        
        if (!creditNoteHeaderState.selectedCreditNoteHeaderRows ||
            creditNoteHeaderState.selectedCreditNoteHeaderRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })

    const handleRowDoubleClick = useCallback((creditNoteHdr: CreditNoteHeaderEntity) => {
        creditNoteHeaderVM.onRowDoubleClick(creditNoteHdr);
    }, [creditNoteHeaderVM])

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        creditNoteHeaderVM.updateSelectedCharges(selectedRows);
      }, [creditNoteHeaderVM])


    const memoCreditNoteHeaderTable = useMemo(() => {

        return (
        <HPHTable
            id='credit-note-header-table'
            isNewColumnSetting={true}
            columns={INITIAL_CREDIT_NOTE_HEADER_COL_DEF.slice()}
            data={transferRowData(creditNoteHeaderState.creditNoteHeaders??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={false}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            isRowHighligted={true}
            rowSelection={"multiple"}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any, creditNoteHdr: CreditNoteHeaderEntity) => handleRowDoubleClick(creditNoteHdr)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 125px)" 
            ref={gridRef}
        />
        );
    },[creditNoteHeaderState.creditNoteHeaders, handleRowDoubleClick, handleSelectionChange])

    return <><TableWrapper>{(creditNoteHeaderState.isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
        {memoCreditNoteHeaderTable}</TableWrapper></>;
}

export default memo(CreditNoteHeaderTablePanel);