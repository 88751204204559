import { useCreditNoteHeaderMaintenanceTracked } from "presentation/store/CreditNote/CreditNoteHeaderMaintenanceProvider";
import CreditNoteDetailContMain from "presentation/view/container/CreditNote/CreditNoteDetailContMain";
import React from "react";
import CreditNoteHeaderMaintenance from "./CreditNoteHeaderMaintenance";

export const CreditNoteMDView: React.FC = () => {
     const [creditNoteHeaderState] = useCreditNoteHeaderMaintenanceTracked();
     const isShowDetail = creditNoteHeaderState.isShowDetail;
   return <>
        {!isShowDetail 
            && <div className="main-comp-wrapper" >
            <CreditNoteHeaderMaintenance/>
        </div>}
    {
        isShowDetail && <div className="main-comp-wrapper" ><CreditNoteDetailContMain/> </div>
    } 

    </>
}
