import { CreditNoteConstant } from "presentation/constant/CreditNote/CreditNoteConstant";
import { hdrStateDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useCreditNoteHeaderVM } from "presentation/hook/CreditNote/useCreditNoteHeaderVM";
import { useCreditNoteHeaderMaintenanceTracked } from "presentation/store/CreditNote/CreditNoteHeaderMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { memo, useMemo } from "react";
import { HPHInputMask, InputDropdown, InputField } from "veronica-ui-component/dist/component/core";
const CreditNoteHeaderSearchForm = () => {    

    const [ creditNoteHeaderState ] = useCreditNoteHeaderMaintenanceTracked();
    const creditNoteHeaderVM = useCreditNoteHeaderVM();
    const creditNoteHeaderSearchCriteria = creditNoteHeaderState.searchCriteria;
    const CREDIT_NOTE_CONSTANT = CreditNoteConstant.Search;

    const memoHdrState = useMemo(() =>
            <div className='flex-row-item'>
                <InputDropdown
                    label={CreditNoteConstant.Header.HDR_STATTE}
                    inputType="freeText"
                    width="180px"
                    mode={'multiple'}
                    sort={false}
                    value={creditNoteHeaderSearchCriteria.states?.map((item) => ({
                        value: item
                    }))}
                    onChange={(e) => creditNoteHeaderVM.onMultipleDropdownChange(e, 'states')}
                    options={hdrStateDropdownOption}/>
            </div>
        , [creditNoteHeaderSearchCriteria.states, creditNoteHeaderVM])
    
    const memoBillToCompany = useMemo(() =>
            <div className='flex-row-item'>
                <InputDropdown
                    label={CreditNoteConstant.Header.BILL_TO_COMPANY}
                    inputType="freeText"
                    width="180px"
                    mode={'multiple'}
                    sort={false}
                    value={creditNoteHeaderSearchCriteria.billToCompanies?.map((item) => ({
                        value: item
                    }))}
                    onChange={(e) => creditNoteHeaderVM.onMultipleDropdownChange(e, 'billToCompanies')}
                    options={creditNoteHeaderState.dynamicOptions.billToCompDropdownOptions}/>
            </div>
        , [creditNoteHeaderSearchCriteria.billToCompanies, creditNoteHeaderState.dynamicOptions.billToCompDropdownOptions, creditNoteHeaderVM])

    const memoCreatedBy = useMemo(() =>
            <div className='flex-row-item'>
                <InputDropdown
                    label={CreditNoteConstant.Header.CREATED_BY}
                    inputType="freeText"
                    width="180px"
                    mode={'single'}
                    sort={false}
                    value={creditNoteHeaderSearchCriteria.createdBy}
                    onChange={(e) => creditNoteHeaderVM.onSingleDropdownChange(e, 'createdBy')}
                    options={creditNoteHeaderState.dynamicOptions.createdByDropdownOptions}/>
            </div>
        , [creditNoteHeaderSearchCriteria.createdBy, creditNoteHeaderState.dynamicOptions.createdByDropdownOptions, creditNoteHeaderVM])
    
        const memoCoVslVoy = useMemo(() =>
        <div className='flex-row-item'>
            <HPHInputMask
                width='180px'
                label={CreditNoteConstant.Search.CO_VSL_VOY}
                value={{
                    co: creditNoteHeaderSearchCriteria.co ?? '',
                    vsl: creditNoteHeaderSearchCriteria.vsl ?? '',
                    voy: creditNoteHeaderSearchCriteria.voy ?? ''
                }}
                fieldConfiguration={{
                    co: { maxLength: 60, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    vsl: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                    voy: { maxLength: 10, autoComplete: "off", valueInNormalCase: true, isNumeric: false },
                }}
                onDataChange={(e: any) => creditNoteHeaderVM.onSearchCoVslVoyChange(e, {
                    co: 'co',
                    vsl: 'vsl',
                    voy: 'voy'
                })}/>
        </div>
    , [creditNoteHeaderSearchCriteria.co, creditNoteHeaderSearchCriteria.vsl, creditNoteHeaderSearchCriteria.voy, creditNoteHeaderVM])

    const memoReqNoFrom = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={CREDIT_NOTE_CONSTANT.REQ_NO_FROM}
                type="text"
                value={creditNoteHeaderSearchCriteria.reqNoFrom || ''}
                onChange={(e: any) => creditNoteHeaderVM.onSearchInputTextChange(e, 'reqNoFrom')}/>
        </div>
    , [CREDIT_NOTE_CONSTANT.REQ_NO_FROM, creditNoteHeaderSearchCriteria.reqNoFrom, creditNoteHeaderVM])

    const memoReqNoTo = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={CREDIT_NOTE_CONSTANT.REQ_NO_TO}
                type="text"
                value={creditNoteHeaderSearchCriteria.reqNoTo || ''}
                onChange={(e: any) => creditNoteHeaderVM.onSearchInputTextChange(e, 'reqNoTo')}/>
        </div>
    , [CREDIT_NOTE_CONSTANT.REQ_NO_TO, creditNoteHeaderSearchCriteria.reqNoTo, creditNoteHeaderVM])

    const memoCreditNoteNo = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={CreditNoteConstant.Header.CREDIT_NOTE_NO}
                type="text"
                value={creditNoteHeaderSearchCriteria.creditNoteNo || ''}
                onChange={(e: any) => creditNoteHeaderVM.onSearchInputTextChange(e, 'creditNoteNo')}/>
        </div>
    , [creditNoteHeaderSearchCriteria.creditNoteNo, creditNoteHeaderVM])

    const memoOriginalInvoiceNo = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={CreditNoteConstant.Header.ORIGINAL_INVOICE_NO}
                type="text"
                value={creditNoteHeaderSearchCriteria.originalInvoiceNo || ''}
                onChange={(e: any) => creditNoteHeaderVM.onSearchInputTextChange(e, 'originalInvoiceNo')}/>
        </div>
    , [creditNoteHeaderSearchCriteria.originalInvoiceNo, creditNoteHeaderVM])

    const memoCreditChargeNoFrom = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={CREDIT_NOTE_CONSTANT.CREDIT_CHARGE_NO_FROM}
                type="text"
                value={creditNoteHeaderSearchCriteria.creditChargeNoFrom || ''}
                onChange={(e: any) => creditNoteHeaderVM.onSearchInputTextChange(e, 'creditChargeNoFrom')}/>
        </div>
    , [CREDIT_NOTE_CONSTANT.CREDIT_CHARGE_NO_FROM, creditNoteHeaderSearchCriteria.creditChargeNoFrom, creditNoteHeaderVM])
    
    const memoCreditChargeNoTo = useMemo(() =>
        <div className='flex-row-item'>
            <InputField
                width='180px'
                maxLength={50}
                label={CREDIT_NOTE_CONSTANT.CREDIT_CHARGE_NO_TO}
                type="text"
                value={creditNoteHeaderSearchCriteria.creditChargeNoTo || ''}
                onChange={(e: any) => creditNoteHeaderVM.onSearchInputTextChange(e, 'creditChargeNoTo')}/>
        </div>
    , [CREDIT_NOTE_CONSTANT.CREDIT_CHARGE_NO_TO, creditNoteHeaderSearchCriteria.creditChargeNoTo, creditNoteHeaderVM])

    const memoCreatedDateFromTo = useMemo(() =>
            <div className='im-flex-row-item'>
                <DateRangePickerComponent
                    label={CreditNoteConstant.Search.CREATED_DATE_RANGE}
                    width='390px'
                    fieldNames={{startField:"createdDateFrom", endField:"createdDateTo"}}
                    dateRange={{startDate:creditNoteHeaderSearchCriteria.createdDateFrom, endDate:creditNoteHeaderSearchCriteria.createdDateTo}}
                    onDatesChange={creditNoteHeaderVM.onDateRangeChange}
                />                
            </div>
        , [creditNoteHeaderSearchCriteria.createdDateFrom, creditNoteHeaderSearchCriteria.createdDateTo, creditNoteHeaderVM.onDateRangeChange])


        
    return <>        
        
        <CriteriaItemContainer>
            {creditNoteHeaderState.enabledSearchCriteria.coVslVoy ? memoCoVslVoy : null}
            {creditNoteHeaderState.enabledSearchCriteria.createdBy ? memoCreatedBy : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {creditNoteHeaderState.enabledSearchCriteria.states ? memoHdrState : null}
            {creditNoteHeaderState.enabledSearchCriteria.billToCompanies ? memoBillToCompany : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {creditNoteHeaderState.enabledSearchCriteria.reqNoFrom ? memoReqNoFrom : null}
            {creditNoteHeaderState.enabledSearchCriteria.reqNoTo ? memoReqNoTo : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {creditNoteHeaderState.enabledSearchCriteria.creditNoteNo ? memoCreditNoteNo : null}
            {creditNoteHeaderState.enabledSearchCriteria.originalInvoiceNo ? memoOriginalInvoiceNo : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {creditNoteHeaderState.enabledSearchCriteria.creditChargeNoFrom ? memoCreditChargeNoFrom : null}
            {creditNoteHeaderState.enabledSearchCriteria.creditChargeNoTo ? memoCreditChargeNoTo : null}
        </CriteriaItemContainer>
        <CriteriaItemContainer>
            {creditNoteHeaderState.enabledSearchCriteria.createdDate ? memoCreatedDateFromTo : null}
        </CriteriaItemContainer>

    </>;
}
export default memo(CreditNoteHeaderSearchForm);