import { CreditNoteHeaderEntity } from "domain/entity/CreditNote/CreditNoteHeaderEntity";
import { CreditNoteConstant } from "presentation/constant/CreditNote/CreditNoteConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { WorkspaceConstant } from "presentation/constant/WorkSpaceConstant";
import { useCreditNoteHeaderVM } from "presentation/hook/CreditNote/useCreditNoteHeaderVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCreditNoteHeaderMaintenanceTracked } from "presentation/store/CreditNote/CreditNoteHeaderMaintenanceProvider";
import { ConfirmDeleteModal } from "presentation/view/components/ConfirmDeleteModal";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, IconButton } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CreditNoteHeaderTitleBar:React.FC = () => {
    const creditNoteHeaderVM = useCreditNoteHeaderVM();
    const CREDIT_NOTE_CONSTANT = CreditNoteConstant.Title;
    const [ creditNoteHeaderState ] = useCreditNoteHeaderMaintenanceTracked();
    const messageBarVM = useMessageBarVM();
    const [anaInfoState] = useANAInfoTracked();
    const {allowCreate,allowUpdate,allowDelete} = anaInfoState;
    const [ isShowDelete, setIsShowDelete ] = useState<boolean>(false);

    const handleSearch = useCallback(() => {
        creditNoteHeaderVM.onSearchClick();
    }, [creditNoteHeaderVM]);


    const handleAdd = useCallback(async() => {
        creditNoteHeaderVM.onAdd();
    }, [creditNoteHeaderVM]);
    
    const handleConfirm = useCallback(async() => {
        if(!creditNoteHeaderState.selectedCreditNoteHeaderRows || creditNoteHeaderState.selectedCreditNoteHeaderRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
        creditNoteHeaderVM.onShowLoading();
        let ids :number[]= [];
        if(creditNoteHeaderState.selectedCreditNoteHeaderRows){
            creditNoteHeaderState.selectedCreditNoteHeaderRows.forEach((hdr) =>{
                if(hdr.id){
                    ids.push(hdr.id);
                }
            });
        }
        creditNoteHeaderVM.onConfirm(ids).then(async (data) =>{
            if(data && "success" === data){
                messageBarVM.showSuccess("Confirm Successful.");
                await creditNoteHeaderVM.getCreditNoteHeaders(creditNoteHeaderState.searchCriteria);
                creditNoteHeaderVM.onHideLoading();
            }else{
                messageBarVM.showError(data);
                await creditNoteHeaderVM.getCreditNoteHeaders(creditNoteHeaderState.searchCriteria);
                creditNoteHeaderVM.onHideLoading();

            }
        });
    }, [creditNoteHeaderState.searchCriteria, creditNoteHeaderState.selectedCreditNoteHeaderRows, creditNoteHeaderVM, messageBarVM]);

    const handleUnConfirm = useCallback(async() => {
        if(!creditNoteHeaderState.selectedCreditNoteHeaderRows || creditNoteHeaderState.selectedCreditNoteHeaderRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
        creditNoteHeaderVM.onShowLoading();
        let ids :number[]= [];
        if(creditNoteHeaderState.selectedCreditNoteHeaderRows){
            creditNoteHeaderState.selectedCreditNoteHeaderRows.forEach((hdr) =>{
                if(hdr.id){
                    ids.push(hdr.id);
                }
            });
        }
        creditNoteHeaderVM.onUnConfirm(ids).then(async (data) =>{
            if(data && "success" === data){
                messageBarVM.showSuccess("UnConfirm Successful.");
                await creditNoteHeaderVM.getCreditNoteHeaders(creditNoteHeaderState.searchCriteria);
                creditNoteHeaderVM.onHideLoading();
            }else{
                messageBarVM.showError(data);
                await creditNoteHeaderVM.getCreditNoteHeaders(creditNoteHeaderState.searchCriteria);
                creditNoteHeaderVM.onHideLoading();
            }
        });
    }, [creditNoteHeaderState.searchCriteria, creditNoteHeaderState.selectedCreditNoteHeaderRows, creditNoteHeaderVM, messageBarVM]);
    
    const isAllowDelete = useCallback((hdr: CreditNoteHeaderEntity) => {
        let ret = true;
        if("NIL" !== hdr.hdrState){
            messageBarVM.showWarining("The selected credit note is not in 'NIL' state.");
            return false;
        }
        if(hdr.reqFormId && "NIL" !== hdr.reqFormState){
            messageBarVM.showWarining("The requisition form in credit note is invoiced.");
            return false;
        }
        return ret;
    },[messageBarVM]);


    const handleDelete = useCallback(async() => {
        if(!creditNoteHeaderState.selectedCreditNoteHeaderRows || creditNoteHeaderState.selectedCreditNoteHeaderRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
        setIsShowDelete(false);
        creditNoteHeaderVM.onShowLoading();
        for(const hdr of creditNoteHeaderState.selectedCreditNoteHeaderRows){
            if(!isAllowDelete(hdr)){
                creditNoteHeaderVM.onHideLoading();
                return;
            }

        }
        creditNoteHeaderVM.onDelete(creditNoteHeaderState.selectedCreditNoteHeaderRows.map(row => row.id).filter((id): id is number => id !== null)).then((data)=>{
            if(data.success){
                messageBarVM.showSuccess("Delete record successful.");
                creditNoteHeaderVM.getCreditNoteHeaders(creditNoteHeaderState.searchCriteria).then(()=>{
                    creditNoteHeaderVM.onHideLoading();
                }).catch((error) => {
                    creditNoteHeaderVM.onHideLoading();
                })
            }else{
                messageBarVM.showError(data.data??'')
                creditNoteHeaderVM.onHideLoading();
            }
        }).catch((error) => {     
            creditNoteHeaderVM.onHideLoading();
            messageBarVM.showError(error.message)
        });
    }, [creditNoteHeaderState.searchCriteria, creditNoteHeaderState.selectedCreditNoteHeaderRows, creditNoteHeaderVM, isAllowDelete, messageBarVM]);

    const handleDeleteClick = useCallback(() => {
        setIsShowDelete(true);
    }, []);    

    const handleCancel = () => {
        setIsShowDelete(false);
    }

    const handleReprint = useCallback(async() => {
        if(!creditNoteHeaderState.selectedCreditNoteHeaderRows || creditNoteHeaderState.selectedCreditNoteHeaderRows.length === 0){
            messageBarVM.showWarining("Please select a record.");
            return;
        }
    }, [creditNoteHeaderState.selectedCreditNoteHeaderRows, messageBarVM]);

    const isDisable = () => {
        if (creditNoteHeaderState.selectedCreditNoteHeaderRows.length > 0) return false;

        return true;
    }

    const isConfirmDisable = () => {
        if (creditNoteHeaderState.selectedCreditNoteHeaderRows.length > 0 
            && creditNoteHeaderState.selectedCreditNoteHeaderRows.every(entity => entity.hdrState === "NIL")) return false;

        return true;
    }
    const isUnConfirmDisable = () => {
        if (creditNoteHeaderState.selectedCreditNoteHeaderRows.length > 0 
            && creditNoteHeaderState.selectedCreditNoteHeaderRows.every(entity => entity.hdrState === "CFM")) return false;

        return true;
    }
    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{CREDIT_NOTE_CONSTANT.CREDIT_NOTE}</HeaderTitle>
        <StyledAction className="tm-animated-wrapper">
            <IconButton fileName='Icon-search' disabled={false} size='medium' toolTipText={'Search'} toolTipArrow={false} onClick={handleSearch} />
            {(allowCreate) && <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />}
            {(allowUpdate || allowDelete) && <div className="add-seperator"/>}
            {(allowDelete) && <HPHButton disabled={isConfirmDisable()} label={WorkspaceConstant.Common.BUTTON_DELETE} size={'Small'} theme={'Secondary'} onClick={handleDeleteClick} />}
            {(allowUpdate) && <><HPHButton disabled={isDisable()} label={WorkspaceConstant.Common.BUTTON_REPRINT} size={'Small'} theme={'Secondary'} onClick={handleReprint} />
            <div className="add-seperator"/>
            <HPHButton disabled={isUnConfirmDisable()} label={WorkspaceConstant.Common.BUTTON_UNCONFIRM} size={'Small'} theme={'Secondary'} onClick={handleUnConfirm} />
            <HPHButton disabled={isConfirmDisable()} label={WorkspaceConstant.Common.BUTTON_CONFIRM} size={'Small'} theme={'Primary'} onClick={handleConfirm} /></>}

            {isShowDelete && <ConfirmDeleteModal headerText={`${WorkspaceConstant.Common.BUTTON_DELETE} ${CREDIT_NOTE_CONSTANT.CREDIT_NOTE}`} 
            contentText={`${MessageConstant.common.DELETE_ALERT_TITLE}\n${MessageConstant.common.DELETE_ALERT_MESSAGE}`}
            visible={isShowDelete}
            onCancelClick={handleCancel}
            onDeleteClick={handleDelete}
        />}
        </StyledAction>
    </Sidebarheader>
}

export default memo(CreditNoteHeaderTitleBar);