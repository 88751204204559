import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { CreditNoteHeaderMaintenanceProvider } from "presentation/store/CreditNote/CreditNoteHeaderMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { CreditNoteMDView } from "presentation/view/section/CreditNote/CreditNoteMDView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const CreditNoteContMain = () => {
    return <ANAInfoWrapper permission={Permission.CREDIT_NOTE}>
        <MessageBarWrapper>
            <CreditNoteHeaderMaintenanceProvider>
                <GridStyles/>
                <CreditNoteMDView/>
            </CreditNoteHeaderMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default CreditNoteContMain;