import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { CreditNoteHeaderRepoImpl } from "domain/repository/CreditNote/CreditNoteHeaderRepoImpl";
import { UserRepoImpl } from "domain/repository/User/UserRepoImpl";
import { useCreditNoteHeaderMaintenanceTracked } from "presentation/store/CreditNote/CreditNoteHeaderMaintenanceProvider";
import { CreditNoteHeaderVM } from "presentation/viewModel/CreditNote/CreditNoteHeaderVM";
import { useMemo } from "react";

export const useCreditNoteHeaderVM = () => {
    const [, setCreditNoteHeaderState] = useCreditNoteHeaderMaintenanceTracked();
    const creditNoteHeaderVM = useMemo(() =>
        CreditNoteHeaderVM({
            dispatch: [setCreditNoteHeaderState],
            creditNoteHeaderRepo: CreditNoteHeaderRepoImpl(),
            userRepo: UserRepoImpl(),
            companyRepo: CompanyRepoImpl(),

        }), [setCreditNoteHeaderState])

    return creditNoteHeaderVM
}

